export default [
  {
    Date:"14-04-2021 01:22:09 PM",
    PBNO:"PB2SUPO10",
    INVNO:"LMT2009",
    SupplierName : "Ezra Miller",
    DueDate:"21-07-2021",
    Status:"Unpaid",
    Amount:"₹1,384",
    Balance:"₹1,384"
  },
  {
    Date:"13-04-2021 02:22:09 PM",
    PBNO:"PB2SUPO9",
    INVNO:"LMT2008",
    SupplierName : "Grant Gustin",
    DueDate:"22-08-2021",
    Status:"Unpaid",
    Amount:"₹6,384",
    Balance:"₹6,384"
  },
  {
    Date:"12-04-2021 01:22:09 PM",
    PBNO:"PB2SUPO8",
    INVNO:"LMT2007",
    SupplierName : "Diana",
    DueDate:"13-07-2021",
    Status:"Paid",
    Amount:"₹0.00",
    Balance:"₹0.00"
  },
  {
    Date:"09-04-2021 12:29:09 PM",
    PBNO:"PB2SUPO7",
    INVNO:"LMT2006",
    SupplierName : "Ezra Miller",
    DueDate:"29-07-2021",
    Status:"Unpaid",
    Amount:"₹2,612",
    Balance:"₹2,612"
  },
  {
    Date:"02-04-2021 11:22:09 PM",
    PBNO:"PB2SUPO6",
    INVNO:"LMT2005",
    SupplierName : "Ray Fisher",
    DueDate:"30-09-2021",
    Status:"Paid",
    Amount:"₹5,381",
    Balance:"₹5,381"
  },
  {
    Date:"11-04-2021 01:22:09 PM",
    PBNO:"PB2SUPO5",
    INVNO:"LMT2004",
    SupplierName : "Chuck Liddel",
    DueDate:"07-07-2021",
    Status:"Unpaid",
    Amount:"₹2,382",
    Balance:"₹1,382"
  },
  {
    Date:"14-04-2021 01:22:09 PM",
    PBNO:"PB2SUPO4",
    INVNO:"LMT2003",
    SupplierName : "Miesha Tate",
    DueDate:"21-07-2021",
    Status:"Unpaid",
    Amount:"₹12,326",
    Balance:"₹12,326"
  },

  
];
