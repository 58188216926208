import brandTableMock from "./brandTableMock";
import MockUtils from "./mock.utils";

export default function mockBrand(mock) {
  mock.onPost("api/brands").reply(({ data }) => {
    const { brand } = JSON.parse(data);
    const {
      BrandName = "",
    } = brand;

    const id = generateUserId();
    const newBrand = {
      id,
      BrandName
    };
    brandTableMock.push(newBrand);
    return [200, { brand: newBrand }];
  });

  mock.onPost("api/brands/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdBrands = mockUtils.baseFilter(
      brandTableMock,
      queryParams
    );
    return [200, filterdBrands];
  });

  mock.onPost("api/brands/deleteBrands").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = brandTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        brandTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/brands/updateStatusForBrands").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    brandTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/brands\/\d+/).reply(config => {
    const id = config.url.match(/api\/brands\/(\d+)/)[1];
    const brand = brandTableMock.find(el => el.id === +id);
    if (!brand) {
      return [400];
    }

    return [200, brand];
  });

  mock.onPut(/api\/brands\/\d+/).reply(config => {
    const id = config.url.match(/api\/brands\/(\d+)/)[1];
    const { brand } = JSON.parse(config.data);
    const index = brandTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    brandTableMock[index] = { ...brand };
    return [200];
  });

  mock.onDelete(/api\/brands\/\d+/).reply(config => {
    const id = config.url.match(/api\/brands\/(\d+)/)[1];
    const index = brandTableMock.findIndex(el => el.id === +id);
    brandTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = brandTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
