import carTableMock from "./profileTableMock";
import MockUtils from "./mock.utils";

export default function mockProfiles(mock) {
  mock.onPost("api/profiles").reply(({ data }) => {
    const { profile } = JSON.parse(data);
    const {
      productType = "Goods",
      brandName = "DEFAULT",
      categoryName = "Smart Watches",
      displayName = "",
      wareHousee = "Office Warehouse",
      rackName = "In-House",
      unitOrMeasurement = "Litre",
      profileShortCode = "",
      hsnCode = "",
      AllowNegativeStock = "Yes",
      taxPercentage="Excemption",
      PurchaseRate="",
      SalesRate="",
      OfferPrice="",
      Description=""
    } = profile;

    const id = generateProfileId();
    const newProfile = {
      id,
      productType,
      brandName,
      categoryName,
      displayName,
      wareHousee,
      rackName,
      unitOrMeasurement,
      profileShortCode,
      hsnCode,
      AllowNegativeStock,
      taxPercentage,
      PurchaseRate,
      SalesRate,
      OfferPrice,
      Description
    };
    carTableMock.push(newProfile);
    return [200, { profile: newProfile }];
  });

  mock.onPost("api/profiles/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredprofiles = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredprofiles];
  });

  mock.onPost("api/profiles/deleteProfiles").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/profiles/updateStatusForProfiles").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/profiles\/\d+/).reply(config => {
    const id = config.url.match(/api\/profiles\/(\d+)/)[1];
    const profile = carTableMock.find(el => el.id === +id);
    if (!profile) {
      return [400];
    }

    return [200, profile];
  });

  mock.onPut(/api\/profiles\/\d+/).reply(config => {
    const id = config.url.match(/api\/profiles\/(\d+)/)[1];
    const { profile } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...profile };
    return [200];
  });

  mock.onDelete(/api\/profiles\/\d+/).reply(config => {
    const id = config.url.match(/api\/profiles\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateProfileId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}