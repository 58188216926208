export default [
    {
      id: 1,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 2,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 3,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 4,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 5,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 6,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    {
      id: 7,
      productType : "Goods",
      brandName : "DEFAULT",
      categoryName : "Smart Watches",
      displayName : "ido",
      wareHousee : "Office Warehouse",
      rackName : "In-House",
      unitOrMeasurement : "Litre",
      itemShortCode : "432kjlj",
      hsnCode : "ikop9",
      AllowNegativeStock : "Yes",
      taxPercentage:"Excemption",
      PurchaseRate:"22224",
      SalesRate:"654324",
      OfferPrice:"9209",
      Description:"hjdshfjasdhlh jfhsdkhfskh uehufgdhsgfjh"
    },
    
  ];
  