import categoryTableMock from "./categoryTableMock";
import MockUtils from "./mock.utils";

export default function mockCategory(mock) {
  mock.onPost("api/categorys").reply(({ data }) => {
    const { category } = JSON.parse(data);
    const {
      CategoryName = "",
    } = category;

    const id = generateUserId();
    const newCategory = {
      id,
      CategoryName
    };
    categoryTableMock.push(newCategory);
    return [200, { category: newCategory }];
  });

  mock.onPost("api/categorys/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdCategorys = mockUtils.baseFilter(
      categoryTableMock,
      queryParams
    );
    return [200, filterdCategorys];
  });

  mock.onPost("api/categorys/deleteCategorys").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = categoryTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        categoryTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/categorys/updateStatusForCategorys").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    categoryTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/categorys\/\d+/).reply(config => {
    const id = config.url.match(/api\/categorys\/(\d+)/)[1];
    const category = categoryTableMock.find(el => el.id === +id);
    if (!category) {
      return [400];
    }

    return [200, category];
  });

  mock.onPut(/api\/categorys\/\d+/).reply(config => {
    const id = config.url.match(/api\/categorys\/(\d+)/)[1];
    const { category } = JSON.parse(config.data);
    const index = categoryTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    categoryTableMock[index] = { ...category };
    return [200];
  });

  mock.onDelete(/api\/categorys\/\d+/).reply(config => {
    const id = config.url.match(/api\/categorys\/(\d+)/)[1];
    const index = categoryTableMock.findIndex(el => el.id === +id);
    categoryTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = categoryTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
