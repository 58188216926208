export default [
    {
      id: 1,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 2,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 3,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 4,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 5,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 6,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 7,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 8,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 9,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 10,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    {
      id: 11,
      ServiceEngineName : "Test Engine",
      CustomerName : "Leto",
      MachineModel : "M43hohdsk",
      IssueFound : "Bugs while loading",
      EstimatedAmount : 43155,
    },
    
  ];
  