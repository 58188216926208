export default [
  {
    id: 1,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 2,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 3,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 4,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 5,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 6,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 7,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 8,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 8,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 9,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
  {
    id: 10,
    TaxName:"Tax Name test",
    TaxPercentage : "25%",
  },
];
