import carTableMock from "./itemTableMock";
import MockUtils from "./mock.utils";

export default function mockItems(mock) {
  mock.onPost("api/items").reply(({ data }) => {
    const { item } = JSON.parse(data);
    const {
      itemName="DHB-STS-2M-2TW",
      itemNo="I000224",
      hsnCode = "8536",
      tax="18%",
      PurchaseRate="₹2199",
      SalesRate="₹3999",
      OfferPrice="₹3199",
      stockOnHand="0Pcs"
    } = item;

    const id = generateItemId();
    const newItem = {
      id,
      itemName,
      itemNo,
      hsnCode ,
      tax,
      PurchaseRate,
      SalesRate,
      OfferPrice,
      stockOnHand
    };
    carTableMock.push(newItem);
    return [200, { item: newItem }];
  });

  mock.onPost("api/items/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredItems = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredItems];
  });

  mock.onPost("api/items/deleteItems").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/items/updateStatusForItems").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/items\/\d+/).reply(config => {
    const id = config.url.match(/api\/items\/(\d+)/)[1];
    const item = carTableMock.find(el => el.id === +id);
    if (!item) {
      return [400];
    }

    return [200, item];
  });

  mock.onPut(/api\/items\/\d+/).reply(config => {
    const id = config.url.match(/api\/items\/(\d+)/)[1];
    const { item } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...item };
    return [200];
  });

  mock.onDelete(/api\/items\/\d+/).reply(config => {
    const id = config.url.match(/api\/items\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateItemId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}