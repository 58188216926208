export default [
  {
    id: 1,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 2,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 3,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 4,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 5,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 6,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 7,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 8,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 8,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 9,
    WarehouseName:"Warehouse Name test"
  },
  {
    id: 10,
    WarehouseName:"Warehouse Name test"
  },
];
