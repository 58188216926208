import warehouseTableMock from "./warehouseTableMock";
import MockUtils from "./mock.utils";

export default function mockWarehouse(mock) {
  mock.onPost("api/warehouses").reply(({ data }) => {
    const { warehouse } = JSON.parse(data);
    const {
      WarehouseName = ""
    } = warehouse;

    const id = generateUserId();
    const newWarehouse = {
      id,
      WarehouseName
    };
    warehouseTableMock.push(newWarehouse);
    return [200, { warehouse: newWarehouse }];
  });

  mock.onPost("api/warehouses/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdWarehouses = mockUtils.baseFilter(
      warehouseTableMock,
      queryParams
    );
    return [200, filterdWarehouses];
  });

  mock.onPost("api/warehouses/deleteWarehouses").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = warehouseTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        warehouseTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/warehouses/updateStatusForWarehouses").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    warehouseTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/warehouses\/\d+/).reply(config => {
    const id = config.url.match(/api\/warehouses\/(\d+)/)[1];
    const warehouse = warehouseTableMock.find(el => el.id === +id);
    if (!warehouse) {
      return [400];
    }

    return [200, warehouse];
  });

  mock.onPut(/api\/warehouses\/\d+/).reply(config => {
    const id = config.url.match(/api\/warehouses\/(\d+)/)[1];
    const { warehouse } = JSON.parse(config.data);
    const index = warehouseTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    warehouseTableMock[index] = { ...warehouse };
    return [200];
  });

  mock.onDelete(/api\/warehouses\/\d+/).reply(config => {
    const id = config.url.match(/api\/warehouses\/(\d+)/)[1];
    const index = warehouseTableMock.findIndex(el => el.id === +id);
    warehouseTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = warehouseTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
