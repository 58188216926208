import carTableMock from "./creditNoteTableMock";
import MockUtils from "./mock.utils";

export default function mockCreditNotes(mock) {
  mock.onPost("api/creditNotes").reply(({ data }) => {
    const { creditNote } = JSON.parse(data);
    const {
      Date="",
      CBNO="",
      SupplierName="",
      DueDate="",
      Status="",
      SalesRepName="",
      Amount="",
      Balance=""
    } = creditNote;

    const id = generateCreditNoteId();
    const newcreditNote = {
      id,
      Date,
      CBNO,
      SupplierName,
      DueDate,
      Status,
      SalesRepName,
      Amount,
      Balance
    };
    carTableMock.push(newcreditNote);
    return [200, { creditNote: newcreditNote }];
  });

  mock.onPost("api/creditNotes/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredcreditNotes = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredcreditNotes];
  });

  mock.onPost("api/creditNotes/deletecreditNotes").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/creditNotes/updateStatusForcreditNotes").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/creditNotes\/\d+/).reply(config => {
    const id = config.url.match(/api\/creditNotes\/(\d+)/)[1];
    const creditNote = carTableMock.find(el => el.id === +id);
    if (!creditNote) {
      return [400];
    }

    return [200, creditNote];
  });

  mock.onPut(/api\/creditNotes\/\d+/).reply(config => {
    const id = config.url.match(/api\/creditNotes\/(\d+)/)[1];
    const { creditNote } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...creditNote };
    return [200];
  });

  mock.onDelete(/api\/creditNotes\/\d+/).reply(config => {
    const id = config.url.match(/api\/creditNotes\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateCreditNoteId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}