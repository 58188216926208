export default [
  {
    Date:"14-04-2021 01:22:09 PM",
    QuoteNo:"SO00010",
    SupplierName : "Ezra Miller",
    DueDate:"21-07-2021",
    Status:"Open",
    Amount:"₹6,384"
  },
  {
    Date:"13-04-2021 02:22:09 PM",
    QuoteNo:"SO0009",
    SupplierName : "Grant Gustin",
    DueDate:"22-08-2021",
    Status:"Open",
    Amount:"₹13,384"
  },
  {
    Date:"12-04-2021 01:22:09 PM",
    QuoteNo:"SO0008",
    SupplierName : "Diana",
    DueDate:"13-07-2021",
    Status:"Open",
    Amount:"₹0.00"
  },
  {
    Date:"09-04-2021 12:29:09 PM",
    QuoteNo:"SO0007",
    SupplierName : "Ezra Miller",
    DueDate:"29-07-2021",
    Status:"Open",
    Amount:"₹1,634"
  },
  {
    Date:"02-04-2021 11:22:09 PM",
    QuoteNo:"SO0006",
    SupplierName : "Ray Fisher",
    DueDate:"30-09-2021",
    Status:"Open",
    Amount:"₹0.00"
  },
  {
    Date:"11-04-2021 01:22:09 PM",
    QuoteNo:"SO0005",
    SupplierName : "Chuck Liddel",
    DueDate:"07-07-2021",
    Status:"Open",
    Amount:"₹3,632"
  },
  {
    Date:"14-04-2021 01:22:09 PM",
    QuoteNo:"SO0004",
    SupplierName : "Miesha Tate",
    DueDate:"21-07-2021",
    Status:"Open",
    Amount:"₹7,386"
  },

  
];
