
export default [
    {
        id:1,
        ContactNo:"SUP004",
        Name:"Henry",
        CompanyName:"Star Labs",
        Mobile:844674566,
        Debit:"₹2,983",
        Credit:"₹0"
    },
    {
        id:2,
        ContactNo:"CUS0007",
        Name:"John",
        CompanyName:"Sun Network",
        Mobile:9872635236,
        Debit:"₹1,288",
        Credit:"₹0"
    },
    {
        id:3,
        ContactNo:"CUS0006",
        Name:"Diana",
        CompanyName:"Cart Rabbit",
        Mobile:992837322,
        Debit:"₹1822",
        Credit:"₹487"
    },
    {
        id:4,
        ContactNo:"CUS0005",
        Name:"Gustin",
        CompanyName:"Ape Media",
        Mobile:854674566,
        Debit:"₹2,983",
        Credit:"₹182"
    },
    {
        id:5,
        ContactNo:"CUS0004",
        Name:"Vinod",
        CompanyName:"Venus Labs",
        Mobile:999435466,
        Debit:"₹2,989",
        Credit:"₹427"
    },
    {
        id:6,
        ContactNo:"CUS0003",
        Name:"Holmes",
        CompanyName:"Abc Labs",
        Mobile:999974566,
        Debit:"₹2,773",
        Credit:"₹0"
    },
    {
        id:7,
        ContactNo:"CUS0002",
        Name:"Daniel",
        CompanyName:"Bellator Labs",
        Mobile:844674566,
        Debit:"₹2,983",
        Credit:"₹0"
    },
    {
        id:8,
        ContactNo:"CUS0001",
        Name:"Cormier",
        CompanyName:"AKA Labs",
        Mobile:877674566,
        Debit:"₹2,983",
        Credit:"₹0"
    },
    
  ];
  