import carTableMock from "./sampleBillTableMock";
import MockUtils from "./mock.utils";

export default function mockSampleBills(mock) {
  mock.onPost("api/sampleBills").reply(({ data }) => {
    const { sampleBill } = JSON.parse(data);
    const {
      Date="",
      PBNO="",
      INVNO="",
      SupplierName = "",
      DueDate="",
      Status="",
      Amount="",
      Balance=""
    } = sampleBill;

    const id = generateSampleBillId();
    const newSampleBill = {
      id,
      Date,
      PBNO,
      INVNO,
      SupplierName,
      DueDate,
      Status,
      Amount,
      Balance
    };
    carTableMock.push(newSampleBill);
    return [200, { sampleBill: newSampleBill }];
  });

  mock.onPost("api/sampleBills/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredSampleBills = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredSampleBills];
  });

  mock.onPost("api/sampleBills/deleteSampleBills").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/sampleBills/updateStatusForSampleBills").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/sampleBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/sampleBills\/(\d+)/)[1];
    const sampleBill = carTableMock.find(el => el.id === +id);
    if (!sampleBill) {
      return [400];
    }

    return [200, sampleBill];
  });

  mock.onPut(/api\/sampleBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/sampleBills\/(\d+)/)[1];
    const { sampleBill } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...sampleBill };
    return [200];
  });

  mock.onDelete(/api\/sampleBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/sampleBills\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateSampleBillId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}