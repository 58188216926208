export default [
  {
    id: 1,
    RackName:"Rack Name test"
  },
  {
    id: 2,
    RackName:"Rack Name test"
  },
  {
    id: 3,
    RackName:"Rack Name test"
  },
  {
    id: 4,
    RackName:"Rack Name test"
  },
  {
    id: 5,
    RackName:"Rack Name test"
  },
  {
    id: 6,
    RackName:"Rack Name test"
  },
  {
    id: 7,
    RackName:"Rack Name test"
  },
  {
    id: 8,
    RackName:"Rack Name test"
  },
  {
    id: 8,
    RackName:"Rack Name test"
  },
  {
    id: 9,
    RackName:"Rack Name test"
  },
  {
    id: 10,
    RackName:"Rack Name test"
  },
];
