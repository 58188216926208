/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";


export function AdvanceTablesWidget2({ className }) {
  
  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div style={{margin:"2rem 0 0 2rem"}}>
          <h3>
            <span className="card-label font-weight-bolder text-dark">
              Unfilled Item List
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>HSN</th>
                  <th style={{textAlign:"right"}}>Purchase Rs</th>
                  <th style={{textAlign:"right"}}>Sales Rate</th>
                  <th style={{textAlign:"right"}}>Offer Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PIONEER 35SQ MM</td>
                  <td>8544</td>
                  <td style={{textAlign:"right"}}>₹167.00</td>
                  <td style={{textAlign:"right"}}>₹220.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>RILON ARC 300AMPS 1PH</td>
                  <td>8468</td>
                  <td style={{textAlign:"right"}}>₹9,400.00</td>
                  <td style={{textAlign:"right"}}>₹10,000.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>DONGCHENG DSM02</td>
                  <td>8465</td>
                  <td style={{textAlign:"right"}}>₹1,913.56</td>
                  <td style={{textAlign:"right"}}>₹2,800.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>DONGCHENG 110MM</td>
                  <td>8467</td>
                  <td style={{textAlign:"right"}}>₹1,588.64</td>
                  <td style={{textAlign:"right"}}>₹2,800.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>RILON 630AMPS MIG/ARC</td>
                  <td>8468</td>
                  <td style={{textAlign:"right"}}>₹77,000.00</td>
                  <td style={{textAlign:"right"}}>₹68,000.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>FACE SHIELD</td>
                  <td>3926</td>
                  <td style={{textAlign:"right"}}>₹130.00</td>
                  <td style={{textAlign:"right"}}>₹180.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
                <tr>
                  <td>CREDIT CARD SWIPPING</td>
                  <td>8524</td>
                  <td style={{textAlign:"right"}}>₹1.00</td>
                  <td style={{textAlign:"right"}}>₹1.00</td>
                  <td style={{textAlign:"right"}}>₹0.00</td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
