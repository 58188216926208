import carTableMock from "./purchaseOrderTableMock";
import MockUtils from "./mock.utils";

export default function mockPurchaseOrders(mock) {
  mock.onPost("api/purchaseOrders").reply(({ data }) => {
    const { purchaseOrder } = JSON.parse(data);
    const {
      Date="",
      PONO="",
      SupplierName = "",
      DueDate="",
      Status="",
      Amount=""
    } = purchaseOrder;

    const id = generatePurchaseOrderId();
    const newPurchaseOrder = {
      id,
      Date,
      PONO,
      SupplierName,
      DueDate,
      Status,
      Amount
    };
    carTableMock.push(newPurchaseOrder);
    return [200, { purchaseOrder: newPurchaseOrder }];
  });

  mock.onPost("api/purchaseOrders/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredPurchaseOrders = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredPurchaseOrders];
  });

  mock.onPost("api/purchaseOrders/deletePurchaseOrders").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/purchaseOrders/updateStatusForPurchaseOrders").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/purchaseOrders\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseOrders\/(\d+)/)[1];
    const purchaseOrder = carTableMock.find(el => el.id === +id);
    if (!purchaseOrder) {
      return [400];
    }

    return [200, purchaseOrder];
  });

  mock.onPut(/api\/purchaseOrders\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseOrders\/(\d+)/)[1];
    const { purchaseOrder } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...purchaseOrder };
    return [200];
  });

  mock.onDelete(/api\/purchaseOrders\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseOrders\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generatePurchaseOrderId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}