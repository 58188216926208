/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";


export function AdvanceTablesWidget4({ className }) {
  

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div style={{margin:"2rem 0 0 2rem"}}>
          <h3>
            <span className="card-label font-weight-bolder text-dark">
              Top Selling Item
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th style={{textAlign:"right"}}>Quantity</th>
                  <th style={{textAlign:"right"}}>Average Rs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>NUMBER ONE ELWI</td>
                  <td style={{textAlign:"right"}}>1555 pcs</td>
                  <td style={{textAlign:"right"}}>₹303.00</td>
                </tr>
                <tr>
                  <td>JON BHANDARI</td>
                  <td style={{textAlign:"right"}}>1131 pcs</td>
                  <td style={{textAlign:"right"}}>₹80.00</td>
                </tr>
                <tr>
                  <td>NUMBER ONE .8MM</td>
                  <td style={{textAlign:"right"}}>1065 pcs</td>
                  <td style={{textAlign:"right"}}>₹3,959.00</td>
                </tr>
                <tr>
                  <td>ADOR 1.2MM MIG COIL</td>
                  <td style={{textAlign:"right"}}>810 pcs</td>
                  <td style={{textAlign:"right"}}>₹960.00</td>
                </tr>
                <tr>
                  <td>BEST ARC 10G</td>
                  <td style={{textAlign:"right"}}>672 pcs</td>
                  <td style={{textAlign:"right"}}>₹8,436.00</td>
                </tr>
                <tr>
                  <td>WELDING WHITE</td>
                  <td style={{textAlign:"right"}}>496 pcs</td>
                  <td style={{textAlign:"right"}}>₹118.00</td>
                </tr>
                <tr>
                  <td>BOSCH 7* 180*6.6</td>
                  <td style={{textAlign:"right"}}>482 pcs</td>
                  <td style={{textAlign:"right"}}>₹1,122.00</td>
                </tr>
                
                
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
