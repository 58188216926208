import carTableMock from "./purchaseBillTableMock";
import MockUtils from "./mock.utils";

export default function mockPurchaseBills(mock) {
  mock.onPost("api/purchaseBills").reply(({ data }) => {
    const { purchaseBill } = JSON.parse(data);
    const {
      Date="",
      PBNO="",
      INVNO="",
      SupplierName = "",
      DueDate="",
      Status="",
      Amount="",
      Balance=""
    } = purchaseBill;

    const id = generatePurchaseBillId();
    const newPurchaseBill = {
      id,
      Date,
      PBNO,
      INVNO,
      SupplierName,
      DueDate,
      Status,
      Amount,
      Balance
    };
    carTableMock.push(newPurchaseBill);
    return [200, { purchaseBill: newPurchaseBill }];
  });

  mock.onPost("api/purchaseBills/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredPurchaseBills = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredPurchaseBills];
  });

  mock.onPost("api/purchaseBills/deletePurchaseBills").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/purchaseBills/updateStatusForPurchaseBills").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/purchaseBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseBills\/(\d+)/)[1];
    const purchaseBill = carTableMock.find(el => el.id === +id);
    if (!purchaseBill) {
      return [400];
    }

    return [200, purchaseBill];
  });

  mock.onPut(/api\/purchaseBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseBills\/(\d+)/)[1];
    const { purchaseBill } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...purchaseBill };
    return [200];
  });

  mock.onDelete(/api\/purchaseBills\/\d+/).reply(config => {
    const id = config.url.match(/api\/purchaseBills\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generatePurchaseBillId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}