/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";


export function LowStockItems({ className }) {
  

  return (
    <>
      {/* begin::Base Table Widget 6 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div style={{margin:"2rem 0 0 2rem"}}>
          <h3>
            <span className="card-label font-weight-bolder text-dark">
              Low Stock Item
            </span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className="card-body pt-2 pb-0">
          {/* begin::Table */}
          <div className="table-responsive">
            <table className="table table-borderless table-vertical-center">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Stock On Hand</th>
                  <th>Re-order Level</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>BOSCH 4" A60 N BF</td>
                  <td>-346</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>1.6 MM TUNGESTEN ROD</td>
                  <td>-89</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>DEWALT 14 D28730 </td>
                  <td>-1</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>OXYGEN AND ARGON NUT </td>
                  <td>-14</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>GAS HOSE - RED </td>
                  <td>-735</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>GAS HOSE - BLUE </td>
                  <td>-701</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>PIONEER MAKE 70 SQ MM CABLE </td>
                  <td>-28</td>
                  <td>0</td>
                </tr>
                <tr>
                  <td>OMEX 50SQ ISI CABLE </td>
                  <td>-243</td>
                  <td>0</td>
                </tr>
                
              </tbody>
            </table>
          </div>
          {/* end::Table */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Base Table Widget 6 */}
    </>
  );
}
