import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
import {taxsSlice} from "../app/modules/ECommerce/_redux/taxs/taxsSlice";
import {brandsSlice} from "../app/modules/ECommerce/_redux/brands/brandsSlice";
import {categorysSlice} from "../app/modules/ECommerce/_redux/categorys/categorysSlice";
import {warehousesSlice} from "../app/modules/ECommerce/_redux/warehouses/warehousesSlice";
import {racksSlice} from "../app/modules/ECommerce/_redux/racks/racksSlice";
import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
import {contactsSlice} from "../app/modules/ECommerce/_redux/contacts/contactsSlice";
import {purchaseOrdersSlice} from "../app/modules/ECommerce/_redux/purchaseOrders/purchaseOrdersSlice";
import {purchaseBillsSlice} from "../app/modules/ECommerce/_redux/purchaseBills/purchaseBillsSlice";
import {quotationsSlice} from "../app/modules/ECommerce/_redux/quotations/quotationsSlice";
import {invoicesSlice} from "../app/modules/ECommerce/_redux/invoices/invoicesSlice";
import {creditNotesSlice} from "../app/modules/ECommerce/_redux/creditNotes/creditNotesSlice";
import {deliveryChallansSlice} from "../app/modules/ECommerce/_redux/deliveryChallans/deliveryChallansSlice";
import {servicesSlice} from "../app/modules/ECommerce/_redux/services/servicesSlice";
import {sampleBillsSlice} from "../app/modules/ECommerce/_redux/sampleBills/sampleBillsSlice";
import {itemsSlice} from "../app/modules/ECommerce/_redux/items/itemsSlice";
import {profilesSlice} from "../app/modules/ECommerce/_redux/profiles/profilesSlice";
import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  taxs: taxsSlice.reducer,
  brands: brandsSlice.reducer,
  categorys: categorysSlice.reducer,
  warehouses: warehousesSlice.reducer,
  racks: racksSlice.reducer,
  products: productsSlice.reducer,
  contacts: contactsSlice.reducer,
  purchaseOrders: purchaseOrdersSlice.reducer,
  purchaseBills: purchaseBillsSlice.reducer,
  quotations: quotationsSlice.reducer,
  invoices: invoicesSlice.reducer,
  creditNotes: creditNotesSlice.reducer,
  deliveryChallans: deliveryChallansSlice.reducer,
  services: servicesSlice.reducer,
  sampleBills: sampleBillsSlice.reducer,
  items: itemsSlice.reducer,
  profiles: profilesSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
