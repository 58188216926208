export default [
  {
    Date:"14-04-2021 01:22:09 PM",
    DCNO:"DCOOO10",
    SupplierName : "Ezra Miller",
    Amount:"₹6,384"
  },
  {
    Date:"13-04-2021 02:22:09 PM",
    DCNO:"DCOOO9",
    SupplierName : "Grant Gustin",
    Amount:"₹13,384"
  },
  {
    Date:"12-04-2021 01:22:09 PM",
    DCNO:"DCOOO8",
    SupplierName : "Diana",
    Amount:"₹0.00"
  },
  {
    Date:"09-04-2021 12:29:09 PM",
    DCNO:"DCOOO7",
    SupplierName : "Ezra Miller",
    Amount:"₹1,634"
  },
  {
    Date:"02-04-2021 11:22:09 PM",
    DCNO:"DCOOO6",
    SupplierName : "Ray Fisher",
    Amount:"₹0.00"
  },
  {
    Date:"11-04-2021 01:22:09 PM",
    DCNO:"DCOOO5",
    SupplierName : "Chuck Liddel",
    Amount:"₹3,632"
  },
  {
    Date:"14-04-2021 01:22:09 PM",
    DCNO:"DCOOO4",
    SupplierName : "Miesha Tate",
    Amount:"₹7,386"
  },

  
];
