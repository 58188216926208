import contactTableMock from "./contactTableMock";
import MockUtils from "./mock.utils";

export default function mockContacts(mock) {
  mock.onPost("api/contacts").reply(({ data }) => {
    const { contact } = JSON.parse(data);
    const {
      ContactNo = "",
      Name = "",
      CompanyName = "",
      Mobile = "",
      Debit = "",
      Credit = ""
    } = contact;

    const id = generateContactId();
    const newContact = {
      id,
      ContactNo,
      Name,
      CompanyName,
      Mobile,
      Debit,
      Credit
    };
    contactTableMock.push(newContact);
    return [200, { contact: newContact }];
  });

  mock.onPost("api/contacts/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredContacts = mockUtils.baseFilter(contactTableMock, queryParams);
    return [200, filteredContacts];
  });

  mock.onPost("api/contacts/deleteContacts").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = contactTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        contactTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/contacts/updateStatusForContacts").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    contactTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/contacts\/\d+/).reply(config => {
    const id = config.url.match(/api\/contacts\/(\d+)/)[1];
    const contact = contactTableMock.find(el => el.id === +id);
    if (!contact) {
      return [400];
    }

    return [200, contact];
  });

  mock.onPut(/api\/contacts\/\d+/).reply(config => {
    const id = config.url.match(/api\/contacts\/(\d+)/)[1];
    const { contact } = JSON.parse(config.data);
    const index = contactTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    contactTableMock[index] = { ...contact };
    return [200];
  });

  mock.onDelete(/api\/contacts\/\d+/).reply(config => {
    const id = config.url.match(/api\/contacts\/(\d+)/)[1];
    const index = contactTableMock.findIndex(el => el.id === +id);
    contactTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateContactId() {
  const ids = contactTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}