export default [
  {
    Date:"14-04-2021 01:22:09 PM",
    SBNO:"TA222O10",
    CustomerName : "Ezra Miller",
    DueDate:"21-07-2021",
    Status:"Unpaid",
    SalesRepName:"Manoj rk",
    Amount:"₹1,384",
    Balance:"₹1,384"
  },
  {
    Date:"13-04-2021 02:22:09 PM",
    SBNO:"TA222O9",
    CustomerName : "Grant Gustin",
    DueDate:"22-08-2021",
    Status:"Unpaid",
    SalesRepName:"Manoj rk",
    Amount:"₹6,384",
    Balance:"₹6,384"
  },
  {
    Date:"12-04-2021 01:22:09 PM",
    SBNO:"TA222O8",
    CustomerName : "Diana",
    DueDate:"13-07-2021",
    Status:"Paid",
    SalesRepName:"Manoj rk",
    Amount:"₹0.00",
    Balance:"₹0.00"
  },
  {
    Date:"09-04-2021 12:29:09 PM",
    SBNO:"TA222O7",
    CustomerName : "Ezra Miller",
    DueDate:"29-07-2021",
    Status:"Unpaid",
    SalesRepName:"Manoj rk",
    Amount:"₹2,612",
    Balance:"₹2,612"
  },
  {
    Date:"02-04-2021 11:22:09 PM",
    SBNO:"TA222O6",
    CustomerName : "Ray Fisher",
    DueDate:"30-09-2021",
    Status:"Paid",
    SalesRepName:"Manoj rk",
    Amount:"₹5,381",
    Balance:"₹5,381"
  },
  {
    Date:"11-04-2021 01:22:09 PM",
    SBNO:"TA222O5",
    CustomerName : "Chuck Liddel",
    DueDate:"07-07-2021",
    Status:"Unpaid",
    SalesRepName:"Manoj rk",
    Amount:"₹2,382",
    Balance:"₹1,382"
  },
  {
    Date:"14-04-2021 01:22:09 PM",
    SBNO:"TA222O4",
    CustomerName : "Miesha Tate",
    DueDate:"21-07-2021",
    Status:"Unpaid",
    SalesRepName:"Manoj rk",
    Amount:"₹12,326",
    Balance:"₹12,326"
  },

  
];
