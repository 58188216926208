import carTableMock from "./quotationTableMock";
import MockUtils from "./mock.utils";

export default function mockQuotations(mock) {
  mock.onPost("api/quotations").reply(({ data }) => {
    const { quotation } = JSON.parse(data);
    const {
      Date="",
      QuoteNo="",
      SupplierName = "",
      DueDate="",
      Status="",
      Amount=""
    } = quotation;

    const id = generateQuotationId();
    const newQuotation = {
      id,
      Date,
      QuoteNo,
      SupplierName,
      DueDate,
      Status,
      Amount,
    };
    carTableMock.push(newQuotation);
    return [200, { quotation: newQuotation }];
  });

  mock.onPost("api/quotations/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredQuotations = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredQuotations];
  });

  mock.onPost("api/quotations/deleteQuotations").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/quotations/updateStatusForQuotations").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/quotations\/\d+/).reply(config => {
    const id = config.url.match(/api\/quotations\/(\d+)/)[1];
    const quotation = carTableMock.find(el => el.id === +id);
    if (!quotation) {
      return [400];
    }

    return [200, quotation];
  });

  mock.onPut(/api\/quotations\/\d+/).reply(config => {
    const id = config.url.match(/api\/quotations\/(\d+)/)[1];
    const { quotation } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...quotation };
    return [200];
  });

  mock.onDelete(/api\/quotations\/\d+/).reply(config => {
    const id = config.url.match(/api\/quotations\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateQuotationId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}