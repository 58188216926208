import React from "react";
import {
  MixedWidget1,
  AdvanceTablesWidget2,
  LowStockItems,
  AdvanceTablesWidget4,
} from "../widgets";
export function Demo1Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-lg-5 col-xxl-4">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-7 order-2 order-xxl-1">
          <AdvanceTablesWidget2 className="card-stretch gutter-b" />
        </div>
        
      </div>
      <div className="row">
        <div className="col-lg-6">
          <AdvanceTablesWidget4 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-6">
          <LowStockItems className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
