export default [
  {
    id: 1,
    BrandName:"Brand Name test"
  },
  {
    id: 2,
    BrandName:"Brand Name test"
  },
  {
    id: 3,
    BrandName:"Brand Name test"
  },
  {
    id: 4,
    BrandName:"Brand Name test"
  },
  {
    id: 5,
    BrandName:"Brand Name test"
  },
  {
    id: 6,
    BrandName:"Brand Name test"
  },
  {
    id: 7,
    BrandName:"Brand Name test"
  },
  {
    id: 8,
    BrandName:"Brand Name test"
  },
  {
    id: 8,
    BrandName:"Brand Name test"
  },
  {
    id: 9,
    BrandName:"Brand Name test"
  },
  {
    id: 10,
    BrandName:"Brand Name test"
  },
];
