import rackTableMock from "./rackTableMock";
import MockUtils from "./mock.utils";

export default function mockRack(mock) {
  mock.onPost("api/racks").reply(({ data }) => {
    const { rack } = JSON.parse(data);
    const {
      RackName = "",

    } = rack;

    const id = generateUserId();
    const newRack = {
      id,
      RackName,
    };
    rackTableMock.push(newRack);
    return [200, { rack: newRack }];
  });

  mock.onPost("api/racks/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filterdRacks = mockUtils.baseFilter(
      rackTableMock,
      queryParams
    );
    return [200, filterdRacks];
  });

  mock.onPost("api/racks/deleteRacks").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = rackTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        rackTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/racks/updateStatusForRacks").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    rackTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/racks\/\d+/).reply(config => {
    const id = config.url.match(/api\/racks\/(\d+)/)[1];
    const rack = rackTableMock.find(el => el.id === +id);
    if (!rack) {
      return [400];
    }

    return [200, rack];
  });

  mock.onPut(/api\/racks\/\d+/).reply(config => {
    const id = config.url.match(/api\/racks\/(\d+)/)[1];
    const { rack } = JSON.parse(config.data);
    const index = rackTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    rackTableMock[index] = { ...rack };
    return [200];
  });

  mock.onDelete(/api\/racks\/\d+/).reply(config => {
    const id = config.url.match(/api\/racks\/(\d+)/)[1];
    const index = rackTableMock.findIndex(el => el.id === +id);
    rackTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateUserId() {
  const ids = rackTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}
