export default [
    {
      itemName:"DHB-STS-2M-2TW",
      itemNo:"I000227",
      hsnCode : "8536",
      tax:"18%",
      PurchaseRate:"₹2,199",
      SalesRate:"₹3,999",
      OfferPrice:"₹3,199",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"DHB-STS-8M-6S/IF",
      itemNo:"1000246",
      hsnCode : "8536",
      tax:"18%",
      PurchaseRate:"₹3,199",
      SalesRate:"₹4,899",
      OfferPrice:"₹5,699",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"DHB-STS-4S-2SP",
      itemNo:"1000245",
      hsnCode : "8536",
      tax:"18%",
      PurchaseRate:"₹5,699",
      SalesRate:"₹3,827",
      OfferPrice:"₹3,785",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"TIP HOLDER 500A",
      itemNo:"1000244",
      hsnCode : "8205",
      tax:"18%",
      PurchaseRate:"₹4,699",
      SalesRate:"₹6,787",
      OfferPrice:"₹3,685",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"CONVEX MIRROR",
      itemNo:"1000243",
      hsnCode : "8204",
      tax:"18%",
      PurchaseRate:"₹3,332",
      SalesRate:"₹4,787",
      OfferPrice:"₹4,100",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"PROCUT 2 IN 1",
      itemNo:"1000242",
      hsnCode : "8205",
      tax:"5%",
      PurchaseRate:"₹2,332",
      SalesRate:"₹4,657",
      OfferPrice:"₹3,100",
      stockOnHand:"0Pcs"
    },
    {
      itemName:"SOLDERING PASTE",
      itemNo:"1000241",
      hsnCode : "8204",
      tax:"12%",
      PurchaseRate:"₹3,332",
      SalesRate:"₹5,657",
      OfferPrice:"₹4,600",
      stockOnHand:"0Pcs"
    },
    
    
  ];
  