import MockAdapter from "axios-mock-adapter";
import mockAuth from "../../app/modules/Auth/__mocks__/mockAuth";
import mockCustomers from "../../app/modules/ECommerce/__mocks__/mockCustomer";
import mockTaxs from "../../app/modules/ECommerce/__mocks__/mockTax";
import mockBrands from "../../app/modules/ECommerce/__mocks__/mockBrand";
import mockCategorys from "../../app/modules/ECommerce/__mocks__/mockCategory";
import mockWarehouses from "../../app/modules/ECommerce/__mocks__/mockWarehouse";
import mockRacks from "../../app/modules/ECommerce/__mocks__/mockRack";
import mockProducts from "../../app/modules/ECommerce/__mocks__/mockProduct";
import mockContacts from "../../app/modules/ECommerce/__mocks__/mockContact";
import mockPurchaseOrders from "../../app/modules/ECommerce/__mocks__/mockPurchaseOrder";
import mockPurchaseBills from "../../app/modules/ECommerce/__mocks__/mockPurchaseBill";
import mockQuotations from "../../app/modules/ECommerce/__mocks__/mockQuotation";
import mockInvoices from "../../app/modules/ECommerce/__mocks__/mockInvoice";
import mockCreditNotes from "../../app/modules/ECommerce/__mocks__/mockCreditNote";
import mockDeliveryChallans from "../../app/modules/ECommerce/__mocks__/mockDeliveryChallan";
import mockServices from "../../app/modules/ECommerce/__mocks__/mockService";
import mockSampleBills from "../../app/modules/ECommerce/__mocks__/mockSampleBill";
import mockItems from "../../app/modules/ECommerce/__mocks__/mockItem";
import mockProfiles from "../../app/modules/ECommerce/__mocks__/mockProfile";
import mockRemarks from "../../app/modules/ECommerce/__mocks__/mockRemark";
import mockSpecifications from "../../app/modules/ECommerce/__mocks__/mockSpecification";

export default function mockAxios(axios) {
  const mock = new MockAdapter(axios, { delayResponse: 300 });

  mockAuth(mock);
  mockCustomers(mock);
  mockTaxs(mock);
  mockBrands(mock);
  mockCategorys(mock);
  mockWarehouses(mock);
  mockRacks(mock);
  mockContacts(mock);
  mockPurchaseOrders(mock);
  mockPurchaseBills(mock);
  mockQuotations(mock);
  mockInvoices(mock);
  mockCreditNotes(mock);
  mockDeliveryChallans(mock);
  mockServices(mock);
  mockSampleBills(mock);
  mockItems(mock);
  mockProfiles(mock);
  mockProducts(mock);
  mockRemarks(mock);
  mockSpecifications(mock);

  return mock;
}
