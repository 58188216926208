import carTableMock from "./deliveryChallanTableMock";
import MockUtils from "./mock.utils";

export default function mockDeliveryChallans(mock) {
  mock.onPost("api/deliveryChallans").reply(({ data }) => {
    const { deliveryChallan } = JSON.parse(data);
    const {
      Date="",
      DCNO="",
      SupplierName = "",
      Amount=""
  } = deliveryChallan;

  const id = generateDeliveryChallanId();
  const newDeliveryChallan = {
    id,
    Date,
    DCNO,
    SupplierName,
    Amount
  };
  carTableMock.push(newDeliveryChallan);
  return [200, { deliveryChallan: newDeliveryChallan }];
});

  mock.onPost("api/deliveryChallans/find").reply(config => {
    const mockUtils = new MockUtils();
    const { queryParams } = JSON.parse(config.data);
    const filteredDeliveryChallans = mockUtils.baseFilter(carTableMock, queryParams);
    return [200, filteredDeliveryChallans];
  });

  mock.onPost("api/deliveryChallans/deleteDeliveryChallans").reply(config => {
    const { ids } = JSON.parse(config.data);
    ids.forEach(id => {
      const index = carTableMock.findIndex(el => el.id === id);
      if (index > -1) {
        carTableMock.splice(index, 1);
      }
    });
    return [200];
  });

  mock.onPost("api/deliveryChallans/updateStatusForDeliveryChallans").reply(config => {
    const { ids, status } = JSON.parse(config.data);
    carTableMock.forEach(el => {
      if (ids.findIndex(id => id === el.id) > -1) {
        el.status = status;
      }
    });
    return [200];
  });

  mock.onGet(/api\/deliveryChallans\/\d+/).reply(config => {
    const id = config.url.match(/api\/deliveryChallans\/(\d+)/)[1];
    const deliveryChallan = carTableMock.find(el => el.id === +id);
    if (!deliveryChallan) {
      return [400];
    }

    return [200, deliveryChallan];
  });

  mock.onPut(/api\/deliveryChallans\/\d+/).reply(config => {
    const id = config.url.match(/api\/deliveryChallans\/(\d+)/)[1];
    const { deliveryChallan } = JSON.parse(config.data);
    const index = carTableMock.findIndex(el => el.id === +id);
    if (!index) {
      return [400];
    }

    carTableMock[index] = { ...deliveryChallan };
    return [200];
  });

  mock.onDelete(/api\/deliveryChallans\/\d+/).reply(config => {
    const id = config.url.match(/api\/deliveryChallans\/(\d+)/)[1];
    const index = carTableMock.findIndex(el => el.id === +id);
    carTableMock.splice(index, 1);
    if (!index === -1) {
      return [400];
    }

    return [200];
  });
}

function generateDeliveryChallanId() {
  const ids = carTableMock.map(el => el.id);
  const maxId = Math.max(...ids);
  return maxId + 1;
}